import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Intro from "../components/Intro";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

const Services = (props) => {
  const { t } = useTranslation();
  const services = props.data.services.edges;

  return (
    <Layout bodyClass="page-services">
      <SEO title={t("บริการ")} />
      <Intro
        imgAlt={t("บริการ")}
        imgSrc="/images/hero/banner-service.jpg"
        introImageAbsolute={true}
        introImageHideOnMobile={false}
      >
        <div>
          <h1>{t("บริการที่เติบโตไปพร้อมกับธุรกิจของคุณ")}</h1>
          <p>
            {t(
              "บริการเคลือบสีฝุ่น (Powder Coating) ลงบนชิ้นงานที่เป็นโลหะ เหล็ก และอลูมิเนียม เพื่อใช้ในโรงงานอุตสาหกรรมต่าง ๆ ให้กับลูกค้า เช่น อุตสาหกรรมเฟอร์นิเจอร์, มอเตอร์, รถเข็น, ชั้น วางของ, เตียง,อลูมิเนียมโปรไฟล์, ชิ้นส่วนรถยนต์และอื่น ๆ อีกมากมาย"
            )}
          </p>
        </div>
      </Intro>

      <div className="container pb-6">
        <div className="row">
          {services.map((edge) => (
            <div key={edge.node.id} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.fields.slug}>
                      {t(edge.node.frontmatter.title)}
                    </Link>
                  </h2>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/services/.*/" }
        frontmatter: { lang: { eq: $language } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            lang
          }
        }
      }
    }
  }
`;

export default Services;

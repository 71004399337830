import React from "react";

const Intro = ({
  children,
  imgAlt,
  imgSrc,
  introImageAbsolute = true,
  introImageHideOnMobile = false,
}) => {
  const introImageClasses = `intro-image ${
    introImageAbsolute && "intro-image-absolute"
  } ${introImageHideOnMobile && "intro-image-hide-mobile"}`;
  return (
    <div className="intro">
      <div className="container">
        <div className="row justify-content-start">
          <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
            <div>{children}</div>
          </div>
          <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
            <img alt={imgAlt} className={introImageClasses} src={imgSrc} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
